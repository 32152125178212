var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vh-100"},[_c('div',{staticClass:"h-100 d-flex flex-column justify-content-between align-items-center p-5"},[_c('h5',{staticClass:"text-center public-title"},[_c('strong',[_vm._v(_vm._s(_vm.getPreference.additional_metadata.vms_kiosk_welcome_message)+" ")])]),_c('div',{staticClass:"public-image"},[_c('b-img',{attrs:{"fluid":"","center":"","src":_vm.showImage(
            _vm.getPreference.additional_metadata.vms_kiosk_branding_image
          )}})],1),_c('div',{staticClass:"public-text"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                name: 'kiosk2-registration'
              },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate }){return [_c('b-button',{staticClass:"text-center",attrs:{"variant":"primary","href":href,"block":""},on:{"click":navigate}},[_vm._v(" SELF CHECK-IN ")])]}}])})],1),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                name: 'kiosk2-visitation-code'
              },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, route, navigate }){return [_c('b-button',{staticClass:"text-center",attrs:{"variant":"primary","href":href,"block":""},on:{"click":navigate}},[_vm._v(" VISITATION CODE ")])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"text-center"},[_vm._v(" By proceeding, you agree to our "),_c('a',{attrs:{"target":"_blank","href":_vm.getPreference.tnc_metadata.kiosk.tnc_url}},[_c('u',[_vm._v("Terms & Conditions")])]),_vm._v(" and confirm that you have understood our "),_c('a',{attrs:{"target":"_blank","href":_vm.getPreference.tnc_metadata.kiosk.policy_url}},[_c('u',[_vm._v("Privacy Policy")])]),_vm._v(". ")]),(
                _vm.getPreference.additional_metadata.vms_pdpa_setting ==
                'save_partial'
              )?_c('div',{staticClass:"text-center"},[_vm._v(" You also agree to us collecting and storing your personal information according to regulations outlined by the PDPA. ")]):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }