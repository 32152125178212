<template>
  <div class="vh-100">
    <div
      class="h-100 d-flex flex-column justify-content-between align-items-center p-5"
    >
      <h5 class="text-center public-title">
        <strong
          >{{ getPreference.additional_metadata.vms_kiosk_welcome_message }}
        </strong>
      </h5>
      <div class="public-image">
        <b-img
          fluid
          center
          :src="
            showImage(
              getPreference.additional_metadata.vms_kiosk_branding_image
            )
          "
        ></b-img>
      </div>

      <div class="public-text">
        <div class="w-100">
          <div class="row">
            <div class="col">
              <router-link
                :to="{
                  name: 'kiosk2-registration'
                }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <b-button
                  variant="primary"
                  class="text-center"
                  :href="href"
                  @click="navigate"
                  block
                >
                  SELF CHECK-IN
                </b-button>
              </router-link>
            </div>
            <div class="col">
              <router-link
                :to="{
                  name: 'kiosk2-visitation-code'
                }"
                v-slot="{ href, route, navigate }"
                custom
              >
                <b-button
                  variant="primary"
                  class="text-center"
                  :href="href"
                  @click="navigate"
                  block
                >
                  VISITATION CODE
                </b-button>
              </router-link>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="text-center">
                By proceeding, you agree to our
                <a
                  target="_blank"
                  :href="getPreference.tnc_metadata.kiosk.tnc_url"
                  ><u>Terms & Conditions</u></a
                >
                and confirm that you have understood our
                <a
                  target="_blank"
                  :href="getPreference.tnc_metadata.kiosk.policy_url"
                  ><u>Privacy Policy</u></a
                >.
              </div>
              <div
                class="text-center"
                v-if="
                  getPreference.additional_metadata.vms_pdpa_setting ==
                  'save_partial'
                "
              >
                You also agree to us collecting and storing your personal
                information according to regulations outlined by the PDPA.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: function () {
    return {
      pageTitle: "KIOSK"
    };
  },
  watch: {
    getPreference: {
      deep: true,
      handler: function (newVal) {
        console.log("newVal");
        console.log(newVal);
      }
    }
    /* getSettings: {
      deep: true,
      handler: function (newVal) {
        console.log("newVal");
        console.log(newVal);
      }
    } */
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    getPreference() {
      return this.$store.getters["vmsPreferences2Module/getPreferences"];
    }
    /* getSettings() {
      return this.$store.getters["vmsSettingsModule/getSettings"];
    } */
  },
  mounted: function () {
    this.$store.dispatch("vmsPreferences2Module/getAllPreferences");
    //this.$store.dispatch("vmsSettingsModule/getSettings");
  },
  methods: {
    showImage: function (img) {
      return `data:image/jpeg;base64,${img}`;
    }
  }
};
</script>

<style lang="scss">
.public-title {
  position: absolute;
  top: 10%;
  width: 100%;
  padding: 0rem 2rem;
  overflow: hidden;
}
.public-image {
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 0rem 2rem;
  overflow: hidden;
}

.public-text {
  position: absolute;
  top: 90%;
  width: 100%;
  padding: 0rem 2rem;
  overflow: hidden;
}
</style>
